/* You can add global styles to this file, and also import other style files */

@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
/* offline Material+Icons */
@import "./assets/iconfont/material-icons.css";
/* @import url("https://fonts.googleapis.com/icon?family=Material+Icons"); */
@import 'bootstrap/dist/css/bootstrap.min.css';
::ng-deep .mat-form-field-appearance-outline .mat-form-field-flex {
    height: 40px !important
}

.DivFont {
    font-size: 12px;
    display: flex;
    padding: 0px;
}

.left {
    text-align: left;
    background-color: #AAFFAA;
}


.DivHeader {
    height: 10%;
}

body {
    background-image: url("/assets/background.png");
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

mat-card-header {
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    color: white;
    height: 60px;
    background-color: #748b31;
    width: 100%;
}

.matCard {
    border-width: 100px;
    border-radius: 60px;
    border-style: solid;
    margin: 10px;
    height: 100%;

}

.mat-table {
    width: 100%;
}

.mat-row {
    width: 100%;
    min-height: 12px;
}

 .mat-mdc-unelevated-button:not(:disabled) {
    background-color: black !important;
    color:white !important;
}
.div-title {
    text-align: center;
    font-size: medium;
    font-weight: bold;
    padding: 4px;
}